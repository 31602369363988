<div
  class="auto-complete"
  [ngClass]="className"
>
  <input
    class="input-auto form-control bg-white border-b-gray-400 p-0 w-full"
    #searchInput
    [placeholder]="labelInput ?? ''"
    [formControl]="dataCtrl"
    [matAutocomplete]="auto"
    matInput
  />
  <label class="closeIcon">
    <mat-icon
      matSuffix
      class="text-[20px]"
      *ngIf="dataCtrl.value"
      (click)="onHandleClear($event)"
      >close</mat-icon
    >
  </label>
  <mat-autocomplete
    class="display-list"
    [panelWidth]="panelWidth ?? 230"
    #auto="matAutocomplete"
    (optionSelected)="selected($event)"
  >
    <mat-option
      *ngFor="let item of dataFiltered | async"
      [value]="item.name"
      >{{ item.name }}</mat-option
    >
  </mat-autocomplete>
</div>
