import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-paginator-custom',
  templateUrl: './paginator-custom.component.html',
  styleUrls: ['./paginator-custom.component.scss']
})
export class PaginatorCustomComponent implements OnChanges {
  @ViewChild('paginator') paginator: MatPaginator;

  @Input() pagiConfigs: any;
  @Input() pageSizeOptions: any;
  @Input() resetPage: boolean;
  @Input() isBorder = true;
  @Output() pageChange: EventEmitter<any> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['pagiConfigs']?.currentValue?.orderBy !== '' &&
      changes['pagiConfigs']?.currentValue?.orderBy !== changes['pagiConfigs']?.previousValue?.orderBy
    ) {
      console.log('call');
      this.paginator?.firstPage();
    }
  }

  pageChanged(event: any) {
    this.pageChange.emit(event);
  }
}
