<div class="px-[40px] py-[17px]">
  <div
    appBanner
    class="bannber-notify"
  ></div>
  <div class="text-[#000] text-[14px] font-[600] py-[17px]">ファイルを追加する</div>
  <div
    class="content-wrapper flex flex-col gap-[31px]"
    [formGroup]="form"
    (dragover)="handleDrag($event)"
  >
    <div
      class="upload-wrapper w-full h-[115px] flex relative border-[1px] border-dashed border-[#c0c0c0] hover:bg-[#c0c0c0] items-center justify-center"
      (drop)="handleDrop($event)"
      [ngClass]="{
        '!bg-[#2196F326]': form.value.file
      }"
    >
      <input
        class="opacity-0 w-full absolute top-0 left-0 h-full cursor-pointer"
        type="file"
        (change)="handleFileChange($event)"
        [multiple]="data?.isUploadMultiple"
      />

      <span *ngIf="!form.value.file">ファイルをアップロード</span>
      <span *ngIf="form.value.file && !data?.isUploadMultiple">
        <div class="truncate w-[400px]">FileName: {{ form.value.file?.name }}</div>
        <div class="">FileSize: {{ (form.value.file?.size / 1024 / 1024).toFixed(2) }} Mb</div>
      </span>
      <span *ngIf="form.value.file && data?.isUploadMultiple">
        <div class="truncate w-[400px]">FileName: {{ form.value.file?.[0]?.name }}</div>
        <div class="">FileSize: {{ (form.value.file?.[0]?.size / 1024 / 1024).toFixed(2) }} Mb</div>
        <span>他{{ form.value.file?.length - 1 }}件</span>
      </span>
    </div>
    <div class="upload-selection flex flex-col gap-[12px] mb-[14px]">
      <mat-radio-group
        aria-label="Select an option"
        formControlName="urlType"
      >
        <mat-radio-button [value]="URL_TYPE['REFERENCE']">
          {{ 'uploadFileDialog.urlReference' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="URL_TYPE['DOWNLOAD']">
          {{ 'uploadFileDialog.urlDownload' | translate }}
        </mat-radio-button>
      </mat-radio-group>
      <mat-form-field>
        <mat-icon
          matPrefix
          class="!w-[16px] !h-[16px] text-[16px] !p-[8px]"
          *ngIf="form.get('urlType')?.value === URL_TYPE['REFERENCE']"
          >open_in_new</mat-icon
        >
        <input
          placeholder="https://"
          formControlName="url"
          matInput
        />
        <mat-error *ngIf="form.get('url')?.invalid">
          {{ messageUrlError }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
</div>

<ng-template #footerTemplate>
  <div class="flex flex-col gap-[10px] items-center">
    <button
      mat-flat-button
      class="w-48 btn-primary !text-white font-bold !text-[14px]"
      (click)="onSave()"
    >
      {{ 'uploadFileDialog.upload' | translate }}
    </button>

    <button
      mat-flat-button
      class="w-48 btn-outline !text-[#515151] font-bold !text-[14px]"
      (click)="onCancel()"
    >
      {{ 'uploadFileDialog.cancel' | translate }}
    </button>
  </div>
</ng-template>
<button
  mat-icon-button
  aria-label="close dialog"
  mat-dialog-close
  class="el-close-dialog"
>
  <mat-icon>close</mat-icon>
</button>
