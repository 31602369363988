import {
  Component,
  Input,
  Output,
  OnInit,
  forwardRef,
  ViewChild,
  ElementRef,
  OnChanges,
  EventEmitter
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

export interface DataList {
  id: string;
  name: string;
}

@Component({
  selector: 'autocomplete-input-comp',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteComponent),
      multi: true
    }
  ]
})
export class AutoCompleteComponent implements OnChanges, OnInit {
  @Input() options: DataList[] = [];
  @Input() labelInput?: string = '';
  @Input() control?: any = new FormControl();
  @Input() className? = 'w-[150px]';
  @Input() selectedItem? = '';
  @Input() panelWidth?: number = 230;
  @Output() handleOnChangeData: EventEmitter<any> = new EventEmitter();
  controlAutComplete: any = new FormControl();
  dataCtrl = new FormControl('');
  dataAfterChooen: DataList[] = [];
  dataFiltered: Observable<DataList[]>;
  dataDisplay: string[] = [];
  selectedOptions: DataList[] = [];
  selectedModel = '';
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  ngOnInit(): void {}

  ngOnChanges() {
    const initInput = this.options.find((option) => option.id === this.control.value);
    if (initInput) {
      this.dataCtrl.setValue(initInput.name);
    } else if (this.selectedItem) {
      this.dataCtrl.setValue(this.selectedItem);
    }
    this.dataFiltered = this.dataCtrl.valueChanges.pipe(
      startWith(null),
      map((value: string | null) => (value ? this._filterData(value) : this.options.slice()))
    );
    this.dataAfterChooen = this.options;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.dataDisplay.push(event.option.viewValue);
    const value = (event.option.viewValue || '').trim();
    if (value) {
      const selectedOptionIndex = this.dataAfterChooen.findIndex((option) => option.name === value);
      if (selectedOptionIndex !== -1) {
        const selectedOption = this.dataAfterChooen[selectedOptionIndex];
        this.dataAfterChooen.splice(selectedOptionIndex, 1);
        this.selectedOptions.push(selectedOption);
        this.control.setValue(selectedOption.id);
        this.dataCtrl.setValue(selectedOption.name);
        this.handleOnChangeData?.emit(selectedOption.id);
      }
    }
    // this.searchInput.nativeElement.value = '';
  }

  onHandleClear(event: Event) {
    this.dataCtrl.setValue('');
    this.handleOnChangeData?.emit();
  }

  private _filterData(value: string): DataList[] {
    const filterValue = value.toLowerCase();
    return this.dataAfterChooen.filter((data) => data.name.toLowerCase().includes(filterValue));
  }
}
