<div class="flex flex-row main">
  <ng-container *ngIf="isClosed">
    <div class="flex w-[40px] shrink-0 justify-center relative border-r-[1px]">
      <mat-icon
        fontIcon="menu"
        (click)="handleOpen()"
        class="cursor-pointer toggleIcon justify-center items-center !flex text-[24px] openIconTransition animated"
      ></mat-icon>
    </div>
  </ng-container>

  <mat-sidenav-container class="sidenav-container w-full">
    <mat-sidenav
      #drawer1
      class="sidenav w-56"
      fixedInViewport
      [attr.role]="(isHandset$ | async).attr"
      [mode]="(isHandset$ | async).mode"
      [opened]="!isClosed"
      [disableClose]="true"
    >
      <mat-toolbar class="flex flex-wrap flex-col !h-[57px] !bg-white relative !mt-[6px]">
        <div class="flex flex-row justify-between items-center w-full gap-[10px] h-full">
          <div
            class="h-full w-full flex justify-center items-center cursor-pointer"
            (click)="!isBuyer && (isOpenSwitchTenantSaler = true)"
          >
            <div
              *ngIf="!getLogo()"
              class="logo__block"
            >
              <img
                class="h-full w-auto"
                src="/assets/images/image-not-found.png"
              />
            </div>
            <div
              *ngIf="getLogo()"
              class="logo__block"
            >
              <img
                class="h-full w-auto"
                [src]="getLogo()"
              />
            </div>
          </div>
          <mat-icon
            class="flex-none icon-display cursor-pointer justify-center items-center !flex !w-[18px] icon-navbar"
            fontIcon="keyboard_tab"
            (click)="handleCloseSidebar()"
          ></mat-icon>
        </div>
        <app-domain-component
          class="bg-white absolute top-[19px] left-[210px] z-10"
          [isOpenSwitchTenant]="isOpenSwitchTenantSaler"
          *ngIf="!isBuyer && isOpenSwitchTenantSaler"
          (closeSwitchTenant)="isOpenSwitchTenantSaler = false"
        ></app-domain-component>
      </mat-toolbar>

      <mat-accordion
        class="sidebar"
        *ngIf="!isBuyer"
      >
        <mat-expansion-panel
          class="!shadow-none"
          [expanded]="true"
        >
          <mat-expansion-panel-header class="header-menu !h-[46px] mt-[9px]">
            <mat-panel-title class="font-extrabold text-[14px]">
              {{ 'loggedDashboard.sidebar.srmSeller' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-expansion-panel
            class="!shadow-none"
            [expanded]="true"
          >
            <mat-expansion-panel-header class="headerSmallPannel">
              <mat-panel-title class="font-extrabold text-base">
                <div class="flex gap-1 items-center">
                  <span class="material-symbols-outlined text-[23px] font-light"> database </span>
                  <span class="font-extrabold">{{ 'loggedDashboard.sidebar.productDatabase' | translate }}</span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div>
              <div
                class="flex items-center h-8 cursor-pointer item-link"
                (click)="switchPageDBMaterial()"
              >
                {{ 'loggedDashboard.sidebar.productList' | translate }}
              </div>
              <div
                class="flex items-center h-8 cursor-pointer item-link"
                [routerLink]="['/db-material/register-material']"
                *ngIf="canManageMaterial"
              >
                {{ 'loggedDashboard.sidebar.productRegistration' | translate }}
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel
            class="!shadow-none"
            [expanded]="true"
          >
            <mat-expansion-panel-header class="headerSmallPannel">
              <mat-panel-title class="font-extrabold text-base">
                <div class="flex gap-1 items-center">
                  <span class="material-symbols-outlined text-[23px] font-light"> storefront </span>
                  <span class="font-extrabold">{{ 'loggedDashboard.sidebar.showroom' | translate }}</span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div>
              <div
                class="flex items-center h-8 cursor-pointer item-link"
                (click)="switchInShowroomTab('showroom')"
              >
                {{ 'loggedDashboard.sidebar.showroomList' | translate }}
              </div>
              <div
                class="flex items-center h-8 cursor-pointer item-link"
                (click)="switchInShowroomTab('showroom/register-showroom')"
              >
                {{ 'loggedDashboard.sidebar.showroomRegister' | translate }}
              </div>
              <div
                class="flex items-center h-8 cursor-pointer item-link"
                (click)="switchShowroomProduct()"
              >
                {{ 'loggedDashboard.sidebar.showroomProductList' | translate }}
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel
            class="!shadow-none"
            [expanded]="true"
          >
            <mat-expansion-panel-header class="headerSmallPannel">
              <mat-panel-title class="font-extrabold text-base">
                <div class="flex gap-1 items-center">
                  <span class="material-symbols-outlined text-[23px] font-light"> description </span>
                  <span class="font-extrabold"> {{ 'loggedDashboard.sidebar.orderManagementSeller' | translate }}</span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div>
              <div
                class="flex items-center h-8 cursor-pointer item-link"
                (click)="switchPageOrder('seller')"
              >
                {{ 'loggedDashboard.sidebar.orderListSeller' | translate }}
              </div>
            </div>
          </mat-expansion-panel>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion class="sidebar">
        <mat-expansion-panel
          class="!shadow-none"
          [expanded]="true"
        >
          <mat-expansion-panel-header class="header-menu !h-[46px]">
            <mat-panel-title class="font-extrabold text-[14px]">
              {{ 'loggedDashboard.sidebar.srmBuyer' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-expansion-panel
            class="!shadow-none"
            [expanded]="true"
          >
            <mat-expansion-panel-header class="headerSmallPannel">
              <mat-panel-title class="font-extrabold text-base">
                <div class="flex gap-1 items-center">
                  <span class="material-symbols-outlined text-[23px] font-light"> storefront </span>
                  <span class="font-extrabold">{{ 'loggedDashboard.sidebar.showroom' | translate }}</span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div>
              <div
                class="flex items-center h-8 cursor-pointer item-link"
                (click)="switchShowroomProduct(true)"
              >
                {{ 'loggedDashboard.sidebar.showroomBuyerProductList' | translate }}
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel
            class="!shadow-none"
            [expanded]="true"
          >
            <mat-expansion-panel-header class="headerSmallPannel">
              <mat-panel-title class="font-extrabold text-base">
                <div class="flex gap-1 items-center">
                  <span class="material-symbols-outlined text-[23px] font-light"> edit_document </span>
                  <span class="font-extrabold"> {{ 'loggedDashboard.sidebar.orderManagementBuyer' | translate }}</span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div>
              <div
                class="flex items-center h-8 cursor-pointer item-link"
                (click)="switchPageOrder('buyer')"
              >
                {{ 'loggedDashboard.sidebar.orderListBuyer' | translate }}
              </div>
              <div
                class="flex items-center h-8 cursor-pointer item-link"
                (click)="switchRegisterProduct()"
              >
                {{ 'loggedDashboard.sidebar.orderForm' | translate }}
              </div>
            </div>
          </mat-expansion-panel>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion
        class="sidebar"
        *ngIf="isBuyer"
        (click)="isOpenSwitchTenantBuyer = true"
      >
        <div
          class="open-tenant flex items-center !h-[36px] cursor-pointer item-link !pl-[0px] !mx-[15px] justify-between !text-[#515151] relative"
        >
          <span class="!text-[14px] font-extrabold">
            {{ 'loggedDashboard.sidebar.srmSaleSwitchDomain' | translate }}
          </span>
          <span class="material-symbols-outlined text-[23px] !w-[23px] font-normal"> open_in_new </span>
          <app-domain-component
            class="bg-white absolute z-10 top-[0px] left-[190px]"
            [isOpenSwitchTenant]="isOpenSwitchTenantBuyer"
            [isBuyer]="isBuyer"
            *ngIf="isBuyer && isOpenSwitchTenantBuyer"
            (closeSwitchTenant)="isOpenSwitchTenantBuyer = false"
          ></app-domain-component>
        </div>
      </mat-accordion>

      <mat-accordion
        class="sidebar"
        (click)="isOpenSwitchTenantPlm = true"
      >
        <div
          class="open-tenant flex items-center !h-[46px] cursor-pointer item-link !pl-[0px] !mx-[15px] justify-between !text-[#515151] relative"
        >
          <span class="!text-[14px] font-extrabold">PLM </span>
          <span class="material-symbols-outlined text-[23px] !w-[23px] font-normal">open_in_new</span>
          <app-domain-component
            class="bg-white absolute z-10 top-[0px] left-[190px]"
            [isOpenSwitchTenant]="isOpenSwitchTenantPlm"
            *ngIf="isOpenSwitchTenantPlm"
            [isPlm]="true"
            (closeSwitchTenant)="isOpenSwitchTenantPlm = false"
          ></app-domain-component>
        </div>
      </mat-accordion>

      <span class="version-sticky">{{ version }}</span>
    </mat-sidenav>

    <mat-sidenav-content>
      <mat-toolbar class="flex items-center border-b sticky top-0 z-[999] toolbarWrapper !bg-white !h-[50px]">
        <app-profile class="flex w-full px-[18px]"></app-profile>
      </mat-toolbar>
      <app-notify />
      <div class="routerWrapper bg-white">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
