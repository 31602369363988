<div class="flex flex-row main">
  <ng-container *ngIf="isClosed">
    <div class="flex w-[40px] shrink-0 justify-center relative border-r-[1px]">
      <mat-icon
        fontIcon="menu"
        (click)="handleOpen()"
        class="cursor-pointer toggleIcon justify-center items-center !flex text-[24px] openIconTransition animated"
      ></mat-icon>
    </div>
  </ng-container>

  <mat-sidenav-container class="sidenav-container w-full">
    <mat-sidenav
      #drawer1
      class="sidenav w-56"
      fixedInViewport
      [attr.role]="(isHandset$ | async).attr"
      [mode]="(isHandset$ | async).mode"
      [opened]="!isClosed"
      [disableClose]="true"
    >
      <mat-toolbar class="flex flex-wrap flex-col h-28 mb-2">
        <div class="flex flex-row justify-between items-center w-full gap-[10px] py-3 h-full">
          <div class="h-full w-full flex justify-center items-center">
            <div
              *ngIf="!getLogo()"
              class="logo__block"
            >
              <img
                class="h-full w-auto"
                src="/assets/images/image-not-found.png"
              />
            </div>
            <div
              *ngIf="getLogo()"
              class="logo__block"
            >
              <img
                class="h-full w-auto"
                [src]="getLogo()"
              />
            </div>
          </div>
          <mat-icon
            class="flex-none icon-display cursor-pointer justify-center items-center !flex"
            fontIcon="keyboard_tab"
            (click)="handleCloseSidebar()"
          ></mat-icon>
        </div>
      </mat-toolbar>

      <mat-accordion
        class="sidebar"
        *ngIf="!isBuyer"
      >
        <mat-expansion-panel
          class="!shadow-none"
          [expanded]="true"
        >
          <mat-expansion-panel-header class="!h-12">
            <mat-panel-title class="font-extrabold text-[14px]">
              {{ 'loggedDashboard.sidebar.srmSeller' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-expansion-panel
            class="!shadow-none"
            [expanded]="true"
          >
            <mat-expansion-panel-header class="headerSmallPannel">
              <mat-panel-title class="font-extrabold text-base">
                <div class="flex gap-1 items-center">
                  <span class="material-symbols-outlined text-[23px] font-light"> database </span>
                  <span class="font-extrabold">{{ 'loggedDashboard.sidebar.productDatabase' | translate }}</span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div>
              <div
                class="flex items-center h-8 cursor-pointer item-link"
                (click)="switchPageDBMaterial()"
              >
                {{ 'loggedDashboard.sidebar.productList' | translate }}
              </div>
              <div
                class="flex items-center h-8 cursor-pointer item-link"
                [routerLink]="['/db-material/register-material']"
                *ngIf="canManageMaterial"
              >
                {{ 'loggedDashboard.sidebar.productRegistration' | translate }}
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel
            class="!shadow-none"
            [expanded]="true"
          >
            <mat-expansion-panel-header class="headerSmallPannel">
              <mat-panel-title class="font-extrabold text-base">
                <div class="flex gap-1 items-center">
                  <span class="material-symbols-outlined text-[23px] font-light"> storefront </span>
                  <span class="font-extrabold">{{ 'loggedDashboard.sidebar.showroom' | translate }}</span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div>
              <div
                class="flex items-center h-8 cursor-pointer item-link"
                (click)="switchShowroomList()"
              >
                {{ 'loggedDashboard.sidebar.showroomList' | translate }}
              </div>
              <div
                class="flex items-center h-8 cursor-pointer item-link"
                (click)="switchShowroomProduct()"
              >
                {{ 'loggedDashboard.sidebar.showroomProductList' | translate }}
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel
            class="!shadow-none"
            [expanded]="true"
          >
            <mat-expansion-panel-header class="headerSmallPannel">
              <mat-panel-title class="font-extrabold text-base">
                <div class="flex gap-1 items-center">
                  <span class="material-symbols-outlined text-[23px] font-light"> description </span>
                  <span class="font-extrabold"> {{ 'loggedDashboard.sidebar.orderManagementSeller' | translate }}</span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div>
              <div
                class="flex items-center h-8 cursor-pointer item-link"
                (click)="switchPageOrder('seller')"
              >
                {{ 'loggedDashboard.sidebar.orderListSeller' | translate }}
              </div>
            </div>
          </mat-expansion-panel>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion class="sidebar">
        <mat-expansion-panel
          class="!shadow-none"
          [expanded]="true"
        >
          <mat-expansion-panel-header class="!h-12">
            <mat-panel-title class="font-extrabold text-[14px]">
              {{ 'loggedDashboard.sidebar.srmBuyer' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-expansion-panel
            class="!shadow-none"
            [expanded]="true"
            *ngIf="isBuyer"
          >
            <mat-expansion-panel-header class="headerSmallPannel">
              <mat-panel-title class="font-extrabold text-base">
                <div class="flex gap-1 items-center">
                  <span class="material-symbols-outlined text-[23px] font-light"> storefront </span>
                  <span class="font-extrabold">{{ 'loggedDashboard.sidebar.showroom' | translate }}</span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div>
              <div
                class="flex items-center h-8 cursor-pointer item-link"
                (click)="switchShowroomProduct(true)"
              >
                {{ 'loggedDashboard.sidebar.showroomBuyerProductList' | translate }}
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel
            class="!shadow-none"
            [expanded]="true"
          >
            <mat-expansion-panel-header class="headerSmallPannel">
              <mat-panel-title class="font-extrabold text-base">
                <div class="flex gap-1 items-center">
                  <span class="material-symbols-outlined text-[23px] font-light"> edit_document </span>
                  <span class="font-extrabold"> {{ 'loggedDashboard.sidebar.orderManagementBuyer' | translate }}</span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div>
              <div
                class="flex items-center h-8 cursor-pointer item-link"
                (click)="switchPageOrder('buyer')"
              >
                {{ 'loggedDashboard.sidebar.orderListBuyer' | translate }}
              </div>
              <div
                class="flex items-center h-8 cursor-pointer item-link"
                (click)="switchRegisterProduct()"
              >
                {{ 'loggedDashboard.sidebar.orderForm' | translate }}
              </div>
            </div>
          </mat-expansion-panel>
        </mat-expansion-panel>
      </mat-accordion>

      <span class="version-sticky">{{ version }}</span>
    </mat-sidenav>

    <mat-sidenav-content>
      <mat-toolbar class="flex items-center border-b sticky top-0 z-[999] toolbarWrapper bg-white h-[50px]">
        <app-profile class="flex w-full px-[18px]"></app-profile>
      </mat-toolbar>
      <app-notify />
      <!-- <div class="p-4 pt-0 routerWrapper bg-white"> -->
      <div class="routerWrapper bg-white">
        <router-outlet></router-outlet>
      </div>
      <!-- <app-lang-switcher></app-lang-switcher> -->
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
