import { Injectable } from '@angular/core';
import { LoggedUserResponse } from '../models/auth.model';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from 'src/app/core/models/common.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  public loggedUser = new BehaviorSubject<string>('');
  private tenantObserver = new BehaviorSubject<string>('');
  private domains: { id: string; subDomain: string }[] = [];

  constructor(private httpClient: HttpClient) {
    this.loggedUser.next('{}');
  }

  public get(): string {
    return localStorage.getItem('jwt_token') || '';
  }

  public set(token: string): void {
    if (token == '') {
      return localStorage.removeItem('jwt_token');
    }

    return localStorage.setItem('jwt_token', token);
  }

  public getUser(): string {
    return localStorage.getItem('logged_username') || '{}';
  }

  public getUserObservable(): Observable<string> {
    return this.loggedUser;
  }

  public setUser(user: LoggedUserResponse): void {
    if (user.email == '') {
      return localStorage.removeItem('logged_username');
    }

    if (user?.profileImageUrl) {
      user.profileImageUrl += `?v=${new Date().getTime()}`;
    }
    const userJson = JSON.stringify(user);
    this.loggedUser.next(userJson);

    return localStorage.setItem('logged_username', userJson);
  }

  public removeToken(): void {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('logged_username');
    localStorage.removeItem('tenant_id');
  }

  public hasLogged(): boolean {
    return this.get() !== '';
  }

  public getTenant() {
    return this.tenantObserver.value;
  }

  public setTenant() {
    return this.httpClient
      .get<ApiResponse>(`${environment.common_url}/tenant/config/domains`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      })
      .pipe(
        map((rs) => {
          this.domains = rs.response as any;
          const tenantId = this.getTenantId();
          this.tenantObserver.next(tenantId);
          return tenantId;
        })
      );
  }

  public getDirectUrl(): string {
    return localStorage.getItem('direct_url') || '';
  }

  public setDirectUrl(url: string): void {
    return localStorage.setItem('direct_url', url);
  }

  public removeDirectUrl(): void {
    localStorage.removeItem('direct_url');
  }

  public getTenantLogo(): string {
    return localStorage.getItem('tenant_logo') || '';
  }

  public setTenantLogo(url: string): void {
    return localStorage.setItem('tenant_logo', url);
  }

  private getTenantId() {
    const subDomain = window.location.hostname.split('.')[0];
    return this.domains.find((x) => x.subDomain === subDomain)?.id ?? '';
  }

  public getSubdomain() {
    return this.domains;
  }

  public canManageMaterial(): boolean {
    const user = this.getUser();
    const currentTenantId = this.getTenant();
    if (user) {
      const userParsed = JSON.parse(user);
      if (!userParsed?.isSystemAdmin) {
        const tenant = userParsed?.tenants?.find((item: any) => item.id === currentTenantId) || [];

        const hasPrivilege = tenant?.role === 1 || tenant?.authorityGroups?.some((item: any) => item.canManageMaterial);

        return hasPrivilege;
      }

      return true;
    }

    return false;
  }
  public getTenantGroup() {
    const user = JSON.parse(this.getUser()) || {};
    if (!user.tenants?.length) return { fullAccess: user.isSystemAdmin, group: [], isAdmin: false };
    return {
      group: user.tenants,
      fullAccess: user.isSystemAdmin,
      isAdmin: user.tenants.some((tenant: any) => tenant.role === 1)
    };
  }
}
