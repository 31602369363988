/* eslint-disable no-irregular-whitespace */
import { Injectable } from '@angular/core';

import { TenantResponse } from '../models/tenant.model';
import { TokenService } from '../services/token.service';
import { Role } from '../models/auth.model';
import * as IMask from 'imask';
import * as moment from 'moment';

export enum RoundingType {
  SKU_DETAIL = 1,
  TOTAL = 2
}

export enum RoundingDetailType {
  ROUND_DOWN = 1,
  ROUND_UP = 2,
  ROUND_OFF = 3
}

export enum SearchCondition {
  AND = 'must',
  OR = 'should'
}

export enum NameSpaceSocket {
  ANNOUNCEMENT = 'announcement'
}

export const paginationSizes = [30, 100, 200];

export enum SocketEvent {
  NEW_ANNOUNCEMENT = 'new-announcement'
}

export const FILE_TYPE = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg', 'postscript', 'pdf', 'mp4'];

@Injectable({
  providedIn: 'root'
})
export class UtilsHelper {
  callbackFunc: ReturnType<typeof setTimeout>;
  tenantID: string;
  constructor(private tokenService: TokenService) {
    if (this.tokenService.getTenant()) {
      this.tenantID = this.tokenService.getTenant();
    }
  }

  checkValidOrResetPagination(limit: number) {
    if (!paginationSizes.includes(limit)) {
      return paginationSizes[1];
    }
    return limit;
  }

  isAbsolutedUrl = (path: string): boolean => {
    return new RegExp('^(?:[a-z+]+:)?//', 'i').test(path);
  };

  convertToHalf(val: any) {
    return val.toString().replace(/[！-～]/g, (halfWidthChar: any) => {
      return String.fromCharCode(halfWidthChar.charCodeAt(0) - 0xfee0);
    });
  }

  inputMask = (mask?: any, thousandsSeparator?: any) => {
    return {
      mask: mask ?? Number,
      signed: true, // Allow Negative
      scale: 3,
      max: 999999999999999,
      min: -99999999999999,
      padFractionalZeros: false,
      thousandsSeparator: thousandsSeparator ?? ',',
      radix: '.',
      lazy: false // make placeholder always visible
    };
  };

  dateMask = (formatDate = 'YYYY/MM/DD') => {
    return {
      mask: Date,
      lazy: true,
      pattern: formatDate,
      format: (date: any) => moment(date).format(formatDate),
      parse: (str: any) => moment(str, formatDate),

      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1970,
          to: 9999
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31
        },
        HH: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 23
        },
        mm: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59
        }
      }
    };
  };

  roundNumber(roundingType: RoundingType, value: number, tenant: TenantResponse, currencyCode?: string): number {
    let keyRoundingDetailType: keyof TenantResponse = 'roundingDetailType';
    let keyRoundingNumber: keyof TenantResponse = 'roundingDetailNumber';

    if (tenant?.tenantRounding?.length) {
      const rounding = tenant.tenantRounding.find(
        (round: any) =>
          round.activeStatus === '1' && round.currencyCode === currencyCode && round.roundingType === roundingType
      );

      if (rounding) {
        // 10^0 = 1, 10^1 = 10, 10^2 = 100, 10^3 = 1000....
        const pow = Math.pow(10, rounding?.[keyRoundingNumber] - 1);

        switch (rounding?.[keyRoundingDetailType]) {
          case RoundingDetailType.ROUND_DOWN:
            return Math.floor(value * pow) / pow;
          case RoundingDetailType.ROUND_UP:
            return Math.ceil(value * pow) / pow;
          case RoundingDetailType.ROUND_OFF:
            return Math.round(value * pow) / pow;
        }
      }
    }

    keyRoundingDetailType = 'roundingTotalType';
    keyRoundingNumber = 'roundingTotalNumber';

    const pow = Math.pow(10, tenant?.[keyRoundingNumber] - 1);
    switch (tenant?.[keyRoundingDetailType]) {
      case RoundingDetailType.ROUND_DOWN:
        return Math.floor(value * pow) / pow;
      case RoundingDetailType.ROUND_UP:
        return Math.ceil(value * pow) / pow;
      case RoundingDetailType.ROUND_OFF:
        return Math.round(value * pow) / pow;
    }

    return 0;
  }

  utcDate(date: any): string | undefined {
    if (!date) return undefined;

    return new Date(new Date(new Date(date).setHours(0, 0, 0, 0)).toString().split('GMT')[0] + ' UTC').toISOString();
  }

  // start open search
  keyNested = [
    'businessPartners',
    'businessPartnersSecrets',
    'materialType4.sizeStrings',
    'materialType5.sizeStrings',
    'materialType5.processingTypes',
    'materialType6.sizeStrings',
    'materialType7.sizeStrings',
    'materialType7.processingTypes',
    'materialType8.sizeStrings',
    'materialType8.processingTypes',
    'materialType9.sizeStrings',
    'materialType10.sizeStrings',
    'materialType11.sizeStrings',
    'materialType11.processingTypes',
    'materialHasSku.skuList'
  ];

  // check key is query nested
  isKeyInKeyNested(key: string) {
    let result = {
      path: '',
      isKeyNested: false
    };
    const keyParts = key.split('.');
    for (const nestedKey of this.keyNested) {
      const nestedKeyParts = nestedKey.split('.');
      let isKeyInNested = true;
      for (let i = 0; i < nestedKeyParts.length; i++) {
        if (keyParts[i] !== nestedKeyParts[i]) {
          isKeyInNested = false;
          break;
        }
      }

      if (isKeyInNested) {
        result = {
          path: nestedKey,
          isKeyNested: true
        };
        return result;
      }
    }
    result = {
      path: '',
      isKeyNested: false
    };
    return result;
  }

  // convert query
  keyQuery = (key: any, value: any, type?: any, tabKey?: string, data?: any) => {
    let newKey = key;
    let newValue = value;

    let queryType = type;

    if (tabKey) {
      newKey = `${tabKey}.${key}`;
    }

    if (key === 'keyword') {
      return null;
    }
    if (key === 'materialNo' || key === 'name' || key === 'id' || key === 'materialCode') {
      queryType = 'wildcard';
      newValue = value ? `*${value}*` : '';
      newKey = `${newKey}.keyword`;
      if (newKey && value) {
        return {
          [queryType]: {
            [newKey]: {
              value: `*${value}*`,
              case_insensitive: true
            }
          }
        };
      }
      return null;
    }

    if (newKey.match(/(From)/)) {
      const keyRange = key.slice(0, key.indexOf('From'));
      newKey = tabKey ? `${tabKey}.${keyRange}` : keyRange;
      queryType = 'range';

      let from = value;
      let to = data[keyRange + 'To'];

      const fromX = value;
      const toX = data[keyRange + 'To'];

      if (keyRange.match(/(mSystemSizeStringValue)/)) {
        from = fromX > toX ? toX : fromX;
        to = fromX > toX ? fromX : toX;
      }
      newValue = {};
      if (from) {
        newValue = {
          gte: from
        };
      }
      if (to) {
        newValue = {
          ...newValue,
          lte: to
        };
      }
    } else if (newKey.match(/(To)/)) {
      let keyRange = key.slice(0, key.indexOf('To'));
      if (newKey.match('minimumOrderLotForTotal')) {
        keyRange = 'minimumOrderLotForTotal';
      }
      if (!data[keyRange + 'From']) {
        newKey = tabKey ? `${tabKey}.${keyRange}` : keyRange;
        queryType = 'range';
        newValue = {
          lte: data[keyRange + 'To']
        };
      } else return null;
    }

    if (key.match(/(minimumSize)/) || key.match(/(maximumSize)/)) {
      const sizeQuery = this.convertSizeQuery(key, tabKey, data);

      if (sizeQuery.length) {
        return {
          bool: {
            should: [...sizeQuery]
          }
        };
      } else {
        return null;
      }
    }

    if (newKey && value && newValue) {
      const checkKeyNested = this.isKeyInKeyNested(newKey);
      if (checkKeyNested?.isKeyNested) {
        return {
          nested: {
            path: checkKeyNested.path,
            query: {
              [queryType]: {
                [newKey]: newValue
              }
            },
            ignore_unmapped: true
          }
        };
      } else {
        return {
          [queryType]: {
            [newKey]: newValue
          }
        };
      }
    }

    return null;
  };

  // convert query minimumSize, maximunSize
  convertSizeQuery(key: string, tabKey: any, data: any) {
    let query: any[] = [];

    const valueMinimumSize = data['minimumSize'];
    const valueMaximumSize = data['maximumSize'];

    const keyMinimum = `${tabKey}.minimumSize`;
    const keyMaximum = `${tabKey}.maximumSize`;

    if (
      (key.match(/(minimumSize)/) && valueMinimumSize) ||
      (key.match(/(maximumSize)/) && valueMaximumSize && !valueMinimumSize)
    ) {
      query = this.handleSizeQuery(valueMinimumSize, valueMaximumSize, keyMinimum, keyMaximum);
    }

    return query;
  }

  handleSizeQuery(valueMinimumSize: any, valueMaximumSize: any, keyMinimum: string, keyMaximum: string) {
    const query = [];
    if (valueMinimumSize) {
      query.push({
        bool: {
          must: [
            {
              range: {
                [keyMinimum]: {
                  lte: valueMinimumSize
                }
              }
            },
            {
              range: {
                [keyMaximum]: {
                  gte: valueMinimumSize
                }
              }
            }
          ]
        }
      });

      if (!valueMaximumSize) {
        query.push({
          bool: {
            must: [
              {
                range: {
                  [keyMinimum]: {
                    gte: valueMinimumSize
                  }
                }
              }
            ]
          }
        });
      }
    }
    if (valueMaximumSize) {
      query.push({
        bool: {
          must: [
            {
              range: {
                [keyMinimum]: {
                  lte: valueMaximumSize
                }
              }
            },
            {
              range: {
                [keyMaximum]: {
                  gte: valueMaximumSize
                }
              }
            }
          ]
        }
      });

      if (!valueMinimumSize) {
        query.push({
          bool: {
            must: [
              {
                range: {
                  [keyMaximum]: {
                    lte: valueMaximumSize
                  }
                }
              }
            ]
          }
        });
      }
    }
    if (valueMinimumSize && valueMaximumSize) {
      query.push({
        bool: {
          must: [
            {
              range: {
                [keyMinimum]: {
                  gte: valueMinimumSize
                }
              }
            },
            {
              range: {
                [keyMaximum]: {
                  lte: valueMaximumSize
                }
              }
            }
          ]
        }
      });
      query.push({
        bool: {
          must: [
            {
              range: {
                [keyMinimum]: {
                  lte: valueMinimumSize
                }
              }
            },
            {
              range: {
                [keyMaximum]: {
                  gte: valueMaximumSize
                }
              }
            }
          ]
        }
      });
    }

    return query;
  }

  // convert condition and product info
  queryObjectConvert = (data: any, seriesList?: any[], skuIds?: string[]) => {
    const result: any = [];
    let isComposition = false;

    if (Object.keys(data)?.length) {
      for (const key in data) {
        if (key !== 'orderBy' && !key.match(/(MaxRange)/)) {
          if (key.match(/(Composition)/)) {
            if (!isComposition) {
              isComposition = true;

              const firstMaterialComposition = data['firstMaterialComposition'];
              const firstCompositionMixedRatio = data['firstCompositionMixedRatio'];
              const secondMaterialComposition = data['secondMaterialComposition'];
              const secondCompositionMixedRatio = data['secondCompositionMixedRatio'];
              const shouldQueryCompotion: any[] = [];
              const mustQueryCompotion1: any[] = [];
              const mustQueryCompotion2: any[] = [];

              const keyId = 'totalMaterialCompositionMixRatio.mTenantMaterialCompositionId.keyword';
              const keyRadio = 'totalMaterialCompositionMixRatio.mixedRatio';

              if (firstMaterialComposition) {
                const compotionQuery = this.keyQuery(keyId, data['firstMaterialComposition'], 'match_phrase');
                compotionQuery !== null && mustQueryCompotion1.push(compotionQuery);
              }
              if (firstCompositionMixedRatio) {
                if (data['firstCompositionMixedRatio']) {
                  const result = [];

                  result.push({
                    range: {
                      [keyRadio]: {
                        gte: data['firstCompositionMixedRatio']
                      }
                    }
                  });

                  if (firstMaterialComposition) {
                    result.push({
                      bool: {
                        must_not: {
                          exists: {
                            field: keyRadio
                          }
                        }
                      }
                    });
                  }

                  const radioQuery = {
                    bool: {
                      should: [...result]
                    }
                  };
                  mustQueryCompotion1.push(radioQuery);
                }
              }

              if (secondMaterialComposition) {
                const compotionQuery = this.keyQuery(keyId, data['secondMaterialComposition'], 'match_phrase');
                compotionQuery !== null && mustQueryCompotion2.push(compotionQuery);
              }
              if (secondCompositionMixedRatio) {
                if (data['secondCompositionMixedRatio']) {
                  const result = [];

                  result.push({
                    range: {
                      [keyRadio]: {
                        gte: data['secondCompositionMixedRatio']
                      }
                    }
                  });

                  if (secondMaterialComposition) {
                    result.push({
                      bool: {
                        must_not: {
                          exists: {
                            field: keyRadio
                          }
                        }
                      }
                    });
                  }

                  const radioQuery = {
                    bool: {
                      should: [...result]
                    }
                  };
                  mustQueryCompotion2.push(radioQuery);
                }
              }

              if (mustQueryCompotion1.length) {
                shouldQueryCompotion.push({
                  nested: {
                    path: 'totalMaterialCompositionMixRatio',
                    query: {
                      bool: {
                        must: mustQueryCompotion1
                      }
                    },
                    ignore_unmapped: true
                  }
                });
              }
              if (mustQueryCompotion2.length) {
                shouldQueryCompotion.push({
                  nested: {
                    path: 'totalMaterialCompositionMixRatio',
                    query: {
                      bool: {
                        must: mustQueryCompotion2
                      }
                    },
                    ignore_unmapped: true
                  }
                });
              }

              if (shouldQueryCompotion.length) {
                result.push({
                  bool: {
                    must: shouldQueryCompotion
                  }
                });
              }
            }
          } else {
            if (key === 'mTenantSeries.mTenantSeriesId') {
              const seriesQuery = this.handleSeries(seriesList, data[key]);

              if (seriesQuery?.length) {
                result.push({
                  bool: {
                    should: seriesQuery
                  }
                });
              }
            } else {
              if (!(skuIds && (key.match(/(bulkPrice)/) || key.match(/(costOfGoodsPurchased)/)))) {
                const query = key.match(/(Id)/) ? 'match_phrase' : 'match';
                const attributeQuery = this.keyQuery(key, data[key], query, '', data);
                attributeQuery !== null && result.push(attributeQuery);
              }
            }
          }
        }
      }
    }

    return result;
  };

  // convert tab categories
  shouldQueryCategoriesConvert = (data: any) => {
    const result: any = [];
    for (const key in data) {
      if (data[key].length) {
        for (const item of data[key]) {
          const attributeQuery = this.keyQuery(`${key}.keyword`, item, 'match_phrase');
          attributeQuery && result.push(attributeQuery);
        }
      }
    }
    return result;
  };

  // convert tab tag
  shouldQueryTagConvert = (data: any) => {
    const result: any = [];
    for (const key in data) {
      const querys = [];
      if (data[key].length) {
        for (let i = 0; i < data[key].length; i++) {
          const tag = data[key][i];

          const attributeQuery = {
            match_phrase: {
              [`${key}${key === 'precautionsSearchTags' ? '.mTenantPrecautionsTagId' : '.mTenantTagId'}`]: tag
            }
          };

          attributeQuery && querys.push(attributeQuery);
        }

        if (querys.length) {
          result.push({
            nested: {
              path: key,
              query: {
                bool: {
                  should: querys
                }
              },
              ignore_unmapped: true
            }
          });
        }
      }
    }

    return result;
  };

  // convert tab color
  shouldQueryColorConvert = (data: any) => {
    const result: any = [];

    for (let i = 0; i < data?.length; i++) {
      const color = data[i];

      const attributeQuery = {
        match_phrase: {
          'materialHasSku.skuList.mTenantColorId': color
        }
      };

      attributeQuery && result.push(attributeQuery);
    }

    return result;
  };

  // convert 11 tab common search
  listTabsQueryConvert = (listTabs: any) => {
    const result = [];
    for (const tabKey in listTabs) {
      if (tabKey !== 'product') {
        const objectTab: { [key: string]: any } = listTabs[tabKey];
        const objectResult: any = [];

        if (Object.keys(objectTab)?.length) {
          for (const key in objectTab) {
            if (
              key !== 'firstFiberFinenessFrom' &&
              key !== 'firstFiberFinenessTo' &&
              key !== 'secondFiberFinenessFrom' &&
              key !== 'secondFiberFinenessTo' &&
              key !== 'firstFiberFinenessTypeD' &&
              key !== 'secondFiberFinenessTypeD' &&
              !key.match(/(MaxRange)/)
            ) {
              const query = key.match(/(Id)/) || key === 'id' ? 'match_phrase' : 'match';
              const attributeQuery = this.keyQuery(key, objectTab[key], query, tabKey, objectTab);
              attributeQuery !== null && objectResult.push(attributeQuery);
            }
          }
        }

        if (objectResult.length) {
          result.push({
            bool: {
              must: objectResult
            }
          });
        }
      }
    }
    return result;
  };

  convertParamsOpenSearch(data: any, user: any, seriesList: any[], skuIds?: string[]) {
    let params = null;
    const query: any = { must: [] };

    const condition = data.condition;
    const materialCategories = data.advancedSearch?.materialCategories;
    const commonSearch = data.advancedSearch?.commonSearchItems;
    const commonSearchProduct = data.advancedSearch?.commonSearchItems?.product;
    const tags = data.advancedSearch?.tags;
    const colors = data.advancedSearch?.colors;

    if (condition && Object.keys(condition).length) {
      if (condition.keyword) {
        const queryKeyword = this.handKeyword(condition.keyword);
        if (queryKeyword.length) {
          query['must'].push({
            bool: {
              should: queryKeyword
            }
          });
        }
      }

      const mustQueryCondition = this.queryObjectConvert(condition);
      query['must'].push(...mustQueryCondition);
    }

    if (materialCategories) {
      const shouldQueryCategory = this.shouldQueryCategoriesConvert(materialCategories);
      if (shouldQueryCategory.length) {
        query['must'].push({
          bool: {
            should: shouldQueryCategory
          }
        });
      }
    }

    if (commonSearchProduct && Object.keys(commonSearchProduct).length) {
      const mustQueryProduct = this.queryObjectConvert(commonSearchProduct, seriesList, skuIds);
      query['must'].push(...mustQueryProduct);
    }

    if (commonSearch && Object.keys(commonSearch).length) {
      const shouldQueryCommon = this.listTabsQueryConvert(commonSearch);
      if (shouldQueryCommon.length) {
        query['must'].push({
          bool: {
            should: shouldQueryCommon
          }
        });
      }
    }

    if (tags) {
      const queryTags = this.shouldQueryTagConvert(tags);
      if (queryTags.length) {
        query['must'].push({
          bool: {
            should: queryTags
          }
        });
      }
    }
    if (!skuIds && colors && colors.length) {
      const queryColor = this.shouldQueryColorConvert(colors);
      if (queryColor.length) {
        query['must'].push({
          nested: {
            path: 'materialHasSku.skuList',
            query: {
              bool: {
                should: queryColor
              }
            },
            ignore_unmapped: true
          }
        });
      }
    }

    if (skuIds && skuIds.length) {
      const queryValidFrom = this.querySKUValidFrom(skuIds, commonSearchProduct, colors);
      if (queryValidFrom) {
        query['must'].push({
          nested: {
            path: 'materialHasSku',
            query: {
              bool: {
                should: queryValidFrom
              }
            },
            ignore_unmapped: true
          }
        });
      }
    }

    const authQuery = this.handleAuthority(user);
    if (authQuery?.length) {
      query['must'].push({
        bool: {
          should: authQuery
        }
      });
    }
    params = {
      limit: data.limit,
      page: data.page,
      query: {
        bool: {
          ...query
        }
      },
      selectedFields: [
        'id',
        'tenantId',
        'materialCode',
        'materialNo',
        'name',
        'updatedAt',
        'mTenantManagementClassification',
        'mTenantMaterialCategory1',
        'mTenantMaterialCategory2',
        'mTenantMaterialCategory3',
        'locationBig',
        'locationSmall',
        'locationOrigin',
        'materialImagesAndVideos',
        'mSystemMaterialCategoryGroupType',
        'mTenantSeason'
        // 'mTenantSeries',
        // 'mTenantCountryForOrigin',
        // 'mTenantCountryForShipping',
        // 'mSystemScientificName',
        // 'mTenantWashingIcon',
        // 'mSystemUnitCategory',
        // 'unitConversionRateSettingForSalesAndPurchasesUnitPrice',
        // 'unitConversionRateSettingForInventory',
        // 'unitConversionRateSettingForLotColor',
        // 'minimumOrderLotForTotal',
        // 'standardValueOfMinimumOrderLotForTotal',
        // 'minimumOrderLotForColor',
        // 'standardValueOfMinimumOrderLotForColor',
        // 'scheduledLeadTime',
        // 'scheduledLeadTimeSpecialOrder',
        // 'materialAttachmentForInternal',
        // 'materialAttachmentForShowroom',
        // 'materialHasSku'
        // 'customItems',
        // 'appearanceShapeSearchTags',
        // 'dyeingMethodSearchTags',
        // 'functionSearchTags',
        // 'patternSearchTags',
        // 'precautionsSearchTags',
        // 'processingMethodSearchTags',
        // 'recommendationSearchTags',
        // 'suggestedItemsSearchTags',
        // 'sustainableSearchTags',
        // 'textureSearchTags'
        // 'mTenantUnitForSalesAndPurchasesUnitPrice',
        // 'mTenantUnitForSampleUnitPrice',
        // 'mTenantUnitForInventory',
        // 'mTenantUnitForLotTotal',
        // 'mTenantUnitForLotColor',
        // 'mTenantCurrency',
        // 'mTenantUnitForSize',
        // 'businessPartners',
        // 'businessPartnersSecrets',
        // 'totalMaterialCompositionMixRatio'
        // 'materialType1',
        // 'materialType2',
        // 'materialType3',
        // 'materialType4',
        // 'materialType5'
        // 'materialType6',
        // 'materialType7'
        // 'materialType8',
        // 'materialType9',
        // 'materialType10',
        // 'materialType11',
      ]
    };

    const orderBy = data.condition?.orderBy ?? null;

    if (orderBy) {
      params = { ...params, sort: orderBy };
    }

    return params;
  }

  convertSalerParamsOpenSearch(data: any, user: any, seriesList: any[], skuIds?: string[]) {
    let params = null;
    const query: any = { must: [] };

    const condition = data.condition;
    const materialCategories = data.advancedSearch?.materialCategories;
    const commonSearch = data.advancedSearch?.commonSearchItems;
    const commonSearchProduct = data.advancedSearch?.commonSearchItems?.product;
    const tags = data.advancedSearch?.tags;
    const colors = data.advancedSearch?.colors;

    if (condition && Object.keys(condition).length) {
      if (condition.keyword) {
        const queryKeyword = this.handKeyword(condition.keyword);
        if (queryKeyword.length) {
          query['must'].push({
            bool: {
              should: queryKeyword
            }
          });
        }
      }

      const mustQueryCondition = this.queryObjectConvert(condition);
      query['must'].push(...mustQueryCondition);
    }

    if (materialCategories) {
      const shouldQueryCategory = this.shouldQueryCategoriesConvert(materialCategories);
      if (shouldQueryCategory.length) {
        query['must'].push({
          bool: {
            should: shouldQueryCategory
          }
        });
      }
    }

    if (commonSearchProduct && Object.keys(commonSearchProduct).length) {
      const mustQueryProduct = this.queryObjectConvert(commonSearchProduct, seriesList, skuIds);
      query['must'].push(...mustQueryProduct);
    }

    if (commonSearch && Object.keys(commonSearch).length) {
      const shouldQueryCommon = this.listTabsQueryConvert(commonSearch);
      if (shouldQueryCommon.length) {
        query['must'].push({
          bool: {
            should: shouldQueryCommon
          }
        });
      }
    }

    if (tags) {
      const queryTags = this.shouldQueryTagConvert(tags);
      if (queryTags.length) {
        query['must'].push({
          bool: {
            should: queryTags
          }
        });
      }
    }
    if (!skuIds && colors && colors.length) {
      const queryColor = this.shouldQueryColorConvert(colors);
      if (queryColor.length) {
        query['must'].push({
          nested: {
            path: 'materialHasSku.skuList',
            query: {
              bool: {
                should: queryColor
              }
            },
            ignore_unmapped: true
          }
        });
      }
    }

    if (skuIds && skuIds.length) {
      const queryValidFrom = this.querySKUValidFrom(skuIds, commonSearchProduct, colors);
      if (queryValidFrom) {
        query['must'].push({
          nested: {
            path: 'materialHasSku',
            query: {
              bool: {
                should: queryValidFrom
              }
            },
            ignore_unmapped: true
          }
        });
      }
    }

    const authQuery = this.handleAuthority(user);
    if (authQuery?.length) {
      query['must'].push({
        bool: {
          should: authQuery
        }
      });
    }
    params = {
      limit: data.limit,
      page: data.page,
      query: {
        bool: {
          ...query
        }
      },
      selectedFields: [
        'id',
        'tenantId',
        'materialCode',
        'materialNo',
        'name',
        'updatedAt',
        'mTenantManagementClassification',
        'mTenantMaterialCategory1',
        'mTenantMaterialCategory2',
        'mTenantMaterialCategory3',
        'locationBig',
        'locationSmall',
        'locationOrigin',
        'materialImagesAndVideos',
        'mSystemMaterialCategoryGroupType',
        'mTenantSeason',
        'personInCharge',
        'mTenantUnitForSalesAndPurchasesUnitPrice'
      ]
    };

    const orderBy = data.condition?.orderBy ?? null;

    if (orderBy) {
      params = { ...params, sort: orderBy };
    }

    return params;
  }

  convertBuyerParamsOpenSearch(data: any) {
    console.log(data);
    const { condition, commonSetting, colorSetting, characteristicSetting } = data;

    let params = null;
    const query: any = { must: [] };

    if (condition && Object.keys(condition).length) {
      if (condition.keyword) {
        const queryKeyword = this.handKeyword(condition.keyword);
        if (queryKeyword.length) {
          query['must'].push({
            bool: {
              should: queryKeyword
            }
          });
        }
      }
    }

    if (characteristicSetting && Object.keys(characteristicSetting).length) {
      const queryTags: any = [];
      const tagType: any = {
        1: 'appearanceShapeSearchTags',
        2: 'dyeingMethodSearchTags',
        3: 'functionSearchTags',
        4: 'patternSearchTags',
        5: 'processingMethodSearchTags',
        6: 'recommendationSearchTags',
        7: 'suggestedItemsSearchTags',
        8: 'sustainableSearchTags',
        9: 'textureSearchTags',
        10: 'precautionsSearchTags'
      };

      Object.values(characteristicSetting).forEach(({ tagGroup, tags }: any) => {
        const q: any = {
          nested: {
            path: tagType[tagGroup.tagType],
            query: {
              bool: {
                should: []
              }
            },
            ignore_unmapped: true
          }
        };

        Object.keys(tags).forEach((key: any) => {
          q.nested.query.bool.should.push({
            match_phrase: {
              [`${tagType[tagGroup.tagType]}.mSystemTagId`]: key
            }
          });
        });

        queryTags.push(q);
      });

      if (queryTags.length) {
        query['must'].push({
          bool: {
            should: queryTags
          }
        });
      }
    }

    if (colorSetting && colorSetting.length) {
      const queryColor: any = [];

      Object.keys(colorSetting).forEach((key: any) => {
        queryColor.push({
          match_phrase: {
            'materialHasSku.skuList.mSystemColorId': key
          }
        });
      });

      if (queryColor.length) {
        query['must'].push({
          nested: {
            path: 'materialHasSku.skuList',
            query: {
              bool: {
                should: queryColor
              }
            },
            ignore_unmapped: true
          }
        });
      }
    }

    if (commonSetting && Object.keys(commonSetting).length) {
    }

    params = {
      limit: data.limit,
      page: data.page,
      query: {
        bool: {
          ...query
        }
      },
      selectedFields: [
        'id',
        'materialCode',
        'materialNo',
        'name',
        'updatedAt',
        'mTenantManagementClassification',
        'locationBig',
        'locationSmall',
        'locationOrigin',
        'materialImagesAndVideos',
        'mSystemMaterialCategoryGroupType',
        'mTenantSeason'
      ]
    };

    const mustQueryCondition = this.queryObjectConvert(condition);
    query['must'].push(...mustQueryCondition);

    const orderBy = data.condition?.orderBy ?? null;

    if (orderBy) {
      params = { ...params, sort: orderBy };
    }

    return params;
  }

  handleAuthority(user: any) {
    const tenant: any = user?.tenants?.find((x: any) => x.id == this.tenantID);
    const authorityGroups = tenant?.authorityGroups;
    const isSystemAdmin = user?.isSystemAdmin;

    const role = tenant?.role;
    if (isSystemAdmin || role === Role.MANAGER) {
      return null;
    }

    const result: any[] = [];

    const key1 = 'mTenantAuthorityGroup1.mTenantAuthorityGroupId1';
    const key2 = 'mTenantAuthorityGroup2.mTenantAuthorityGroupId2';
    const key3 = 'mTenantAuthorityGroup3.mTenantAuthorityGroupId3';
    if (role === Role.NORMAL) {
      for (let i = 0; i < authorityGroups.length; i++) {
        const authGroup = authorityGroups[i];
        result.push({
          match_phrase: {
            [key1]: authGroup.id
          }
        });
        result.push({
          match_phrase: {
            [key2]: authGroup.id
          }
        });
        result.push({
          match_phrase: {
            [key3]: authGroup.id
          }
        });
      }
    }
    return result;
  }

  handleSeries(seriesList: any, data: any) {
    const resultQuery: any[] = [];

    const lstSeriesData = seriesList.filter((item: any) => item.name === data);

    for (let i = 0; i < lstSeriesData.length; i++) {
      const el = lstSeriesData[i];
      if (el.id) {
        resultQuery.push({
          match_phrase: {
            'mTenantSeries.mTenantSeriesId': el.id
          }
        });
      }
    }

    return resultQuery;
  }

  handleFiberFineness(data: any, line: number) {
    const valueFrom = line == 1 ? 'firstFiberFinenessFrom' : 'secondFiberFinenessFrom';
    const valueTo = line == 1 ? 'firstFiberFinenessTo' : 'secondFiberFinenessTo';

    const fiberFinenessType =
      line == 1 ? 'mSystemFiberFinenessTypeFor1stFiberFineness' : 'mSystemFiberFinenessTypeFor2ndFiberFineness';

    const fiberFinenessTypeD = line == 1 ? 'firstFiberFinenessTypeD' : 'secondFiberFinenessTypeD';
    const fiberFinenessYarnCountFrom =
      line == 1 ? 'firstFiberFinenessYarnCountFrom' : 'secondFiberFinenessYarnCountFrom';
    const fiberFinenessYarnCountTo = line == 1 ? 'firstFiberFinenessYarnCountTo' : 'secondFiberFinenessYarnCountTo';
    const fiberFinenessYarnCountValueFrom =
      line == 1 ? 'firstFiberFinenessYarnCountValueFrom' : 'secondFiberFinenessYarnCountValueFrom';
    const fiberFinenessYarnCountValueTo =
      line == 1 ? 'firstFiberFinenessYarnCountValueTo' : 'secondFiberFinenessYarnCountValueTo';
    const fiberFinenessDtexValueFrom =
      line == 1 ? 'firstFiberFinenessDtexValueFrom' : 'secondFiberFinenessDtexValueFrom';
    const fiberFinenessDtexValueTo = line == 1 ? 'firstFiberFinenessDtexValueTo' : 'secondFiberFinenessDtexValueTo';

    const type: any = data[fiberFinenessType];
    const fiberTypeD: any = data[fiberFinenessTypeD];
    const from = data[valueFrom];
    const to = data[valueTo];

    switch (type) {
      case 1:
      case '1':
      case 2:
      case '2':
        data[fiberFinenessYarnCountValueFrom] = from;
        data[fiberFinenessYarnCountValueTo] = to;
        data[fiberFinenessYarnCountFrom] = null;
        data[fiberFinenessYarnCountTo] = null;
        data[fiberFinenessDtexValueFrom] = null;
        data[fiberFinenessDtexValueTo] = null;
        break;
      case 3:
      case '3':
      case 4:
      case '4':
        if (fiberTypeD === 'D') {
          data[fiberFinenessYarnCountValueFrom] = null;
          data[fiberFinenessYarnCountValueTo] = null;
          data[fiberFinenessYarnCountFrom] = from;
          data[fiberFinenessYarnCountTo] = to;
          data[fiberFinenessDtexValueFrom] = null;
          data[fiberFinenessDtexValueTo] = null;
        } else if (fiberTypeD === 'dtex') {
          data[fiberFinenessYarnCountValueFrom] = null;
          data[fiberFinenessYarnCountValueTo] = null;
          data[fiberFinenessYarnCountFrom] = null;
          data[fiberFinenessYarnCountTo] = null;
          data[fiberFinenessDtexValueFrom] = from;
          data[fiberFinenessDtexValueTo] = to;
        } else {
          data[fiberFinenessYarnCountValueFrom] = null;
          data[fiberFinenessYarnCountValueTo] = null;
          data[fiberFinenessYarnCountFrom] = null;
          data[fiberFinenessYarnCountTo] = null;
          data[fiberFinenessDtexValueFrom] = null;
          data[fiberFinenessDtexValueTo] = null;
        }
        break;

      default:
        break;
    }

    return data;
  }

  inValidInputSearch(data: any) {
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const dataObj = data[key];

      const objKeys = Object.keys(dataObj);
      for (let j = 0; j < objKeys.length; j++) {
        const childKey = objKeys[j];

        const indexFrom = childKey.length - 4;
        const isFromField = childKey.indexOf('From') === indexFrom;

        if (childKey.match(/From/) && isFromField) {
          const field = childKey.substring(0, indexFrom);
          const fieldTo = field + 'To';

          if (!field.match(/mSystemSizeStringValue/) && dataObj[fieldTo] && dataObj[childKey] > dataObj[fieldTo]) {
            return true;
          }
        }

        if (childKey.match(/minimumSize/)) {
          const fieldTo = 'maximumSize';

          if (+dataObj[fieldTo] && +dataObj[childKey] > +dataObj[fieldTo]) {
            return true;
          }
        }

        if (childKey.match(/FiberFineness/)) {
          const firstValueFrom = dataObj['firstFiberFinenessFrom'];
          const firstValueTo = dataObj['firstFiberFinenessTo'];
          const firstType = dataObj['mSystemFiberFinenessTypeFor1stFiberFineness'];
          const firstDtex = dataObj['firstFiberFinenessTypeD'];
          const secondValueFrom = dataObj['secondFiberFinenessFrom'];
          const secondValueTo = dataObj['secondFiberFinenessTo'];
          const secondType = dataObj['mSystemFiberFinenessTypeFor2ndFiberFineness'];
          const secondDtex = dataObj['secondFiberFinenessTypeD'];

          const check1: boolean =
            (+firstValueFrom > 0 || +firstValueTo > 0) && (firstType == 3 || firstType == 4) && !firstDtex;
          const check2: boolean =
            (+secondValueFrom > 0 || +secondValueTo > 0) && (secondType == 3 || secondType == 4) && !secondDtex;

          if (check1 || check2) {
            return true;
          }
        }
      }
    }
    return false;
  }

  handKeyword(keyword: string) {
    const pattern = /\s+|　+/;
    const keys = keyword.split(pattern);

    const querys = [];

    for (let i = 0; i < keys.length; i++) {
      const item = keys[i];
      if (item) {
        querys.push({
          query_string: {
            default_field: 'keywords',
            query: `*${item}*`
          }
        });
      }
    }

    return querys;
  }

  querySKUValidFrom(data: any, product: any, colors: any) {
    const result: any = [];
    const queryPriceSku = this.queryPriceSku(product);
    const queryColor = this.queryColor(colors);

    for (let i = 0; i < data.length; i++) {
      const queryHasSku: any = { must: [] };
      const skuId = data[i];

      const attributeQuery = {
        match_phrase: {
          'materialHasSku.id': skuId
        }
      };

      if (attributeQuery) {
        queryHasSku['must'].push(attributeQuery);
      }

      if (queryPriceSku.length) {
        queryHasSku['must'].push(...queryPriceSku);
      }

      if (queryColor) {
        queryHasSku['must'].push(queryColor);
      }

      if (queryHasSku['must'].length) {
        result.push({
          bool: { ...queryHasSku }
        });
      }
    }

    return result;
  }

  queryColor(colors: any) {
    let query = null;
    const queryColor = this.shouldQueryColorConvert(colors);
    if (queryColor?.length) {
      query = {
        nested: {
          path: 'materialHasSku.skuList',
          query: {
            bool: {
              should: queryColor
            }
          },
          ignore_unmapped: true
        }
      };
    }

    return query;
  }

  queryPriceSku(product: any) {
    const result: any = [];
    if (Object.keys(product)?.length) {
      for (const key in product) {
        if (!key.match(/MaxRange/) && (key.match(/(bulkPrice)/) || key.match(/(costOfGoodsPurchased)/))) {
          const query = 'range';
          const attributeQuery = this.keyQuery(key, product[key], query, '', product);
          attributeQuery !== null && result.push(attributeQuery);
        }
      }
    }

    return result;
  }
  // end open search

  getYouTubeVideoId(url: string) {
    const pattern =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/)([a-zA-Z0-9_-]{11})/;

    const match = url.match(pattern);

    if (match && match[1]) {
      return match[1];
    }

    return null;
  }

  checkStringWidth(str = '') {
    if (!str) str = '';

    let len = 0;
    for (let i = 0; i < str.length; i++) {
      const code = str.charCodeAt(i);
      if ((code >= 0x0020 && code <= 0x1fff) || (code >= 0xff61 && code <= 0xff9f)) {
        len = 1;
      } else if ((code >= 0x2000 && code <= 0xff60) || code >= 0xffa0) {
        len = 2;
      } else {
        len = 0;
      }
    }

    return len;
  }

  containsFullwidth(text: string): boolean {
    const fullwidthPattern = /[\uFF00-\uFFEF]/;

    return fullwidthPattern.test(text);
  }

  containsFullwidthWhitespace(text: string): boolean {
    const fullwidthWhitespacePattern = /[　]/;

    return fullwidthWhitespacePattern.test(text);
  }

  isHaftWidth(str = '') {
    return this.checkStringWidth(str) === 1;
  }

  isFullWidth(str = '') {
    return this.containsFullwidth(str) || this.containsFullwidthWhitespace(str);
    // return this.checkStringWidth(str) === 2;
  }

  // localStorage
  setStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getStorage(key: string) {
    const val = localStorage.getItem(key);
    return val;
  }

  setStoragePaging(screen: string, data: any) {
    const dataStorage = JSON.stringify(data);
    const currentUser = JSON.parse(this.tokenService.getUser());
    localStorage.setItem(`storagePaging_${screen}_${this.tenantID}_${currentUser?.email}`, dataStorage);
  }

  getStoragePaging(screen: string) {
    const currentUser = JSON.parse(this.tokenService.getUser());
    const val = localStorage.getItem(`storagePaging_${screen}_${this.tenantID}_${currentUser?.email}`);
    return val ? JSON.parse(val) : null;
  }

  setStorageSearchOrder(screen: string, data: any) {
    const getUser = JSON.parse(this.tokenService.getUser());

    const dataStorage = JSON.stringify(data);
    localStorage.setItem(`${screen}_${this.tenantID}_${getUser?.email}`, dataStorage);
  }

  getStorageSearchOrder(screen: string) {
    const getUser = JSON.parse(this.tokenService.getUser());

    const val = localStorage.getItem(`${screen}_${this.tenantID}_${getUser?.email}`);
    return val ? JSON.parse(val) : null;
  }

  removeStorage(key: string) {
    localStorage.removeItem(key);
  }
  // end localStorage
  scrollToItem(id: string, time = 250) {
    try {
      if (this.callbackFunc) clearTimeout(this.callbackFunc);
      this.callbackFunc = setTimeout(() => {
        const elmt = document.querySelector(`#${id}`);
        elmt?.scrollIntoView();
      }, time);
    } catch (error) {
      console.error(`error ${error}`);
    }
  }

  validateFileType(file: any, allowMimes: string[] = FILE_TYPE) {
    let fileType = '';
    if (file.type) {
      fileType = file.type.split('/')[1];
    } else {
      fileType = file.name.split('.')[1];
    }

    return allowMimes.some((type) => fileType.includes(type));
  }

  validateStringLength(str: string, maxHalfWidth: number, maxFullWidth: number) {
    let halfWidthCount = 0;
    let fullWidthCount = 0;

    for (let i = 0; i < str.length; i++) {
      const charCode = str.charCodeAt(i);

      // Kiểm tra ký tự half-width
      if (
        (charCode >= 0x0020 && charCode <= 0x007e) || // Ký tự ASCII
        (charCode >= 0xff61 && charCode <= 0xff9f) // Ký tự half-width tiếng Nhật
      ) {
        halfWidthCount++;
      }
      // Kiểm tra ký tự full-width
      else if (
        (charCode >= 0x3000 && charCode <= 0x30ff) || // Ký tự full-width tiếng Nhật
        (charCode >= 0xff00 && charCode <= 0xffef) // Ký tự full-width khác
      ) {
        fullWidthCount++;
      }
    }

    return halfWidthCount <= maxHalfWidth && fullWidthCount <= maxFullWidth;
  }

  randomAlphaNumeric(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
