import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateHelper } from 'src/app/core/helpers/translate.helper';
import { FILE_TYPE, UtilsHelper } from 'src/app/core/helpers/utils.helper';

import { BannerDirective } from '../../directives/dialog-banner.directive';

type DialogData = {
  isUploadMultiple?: boolean;
  maxTotalFiles?: number;
  isUploadMedia?: boolean;
  allowMimes?: string[];
};

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  @ViewChild(BannerDirective) banner: BannerDirective;
  loading = false;
  form!: FormGroup;
  URL_TYPE = {
    REFERENCE: 2,
    DOWNLOAD: 3
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<UploadFileComponent>,
    public translate: TranslateHelper,
    private fb: FormBuilder,
    private utils: UtilsHelper
  ) {
    this.form = this.fb.group({
      urlType: [this.URL_TYPE['REFERENCE']],
      url: [null, Validators.pattern(/^(http|https):\/\/[^ "]+$/)],
      file: [null]
    });
  }

  ngOnInit(): void {
    if (!this.data) {
      this.data = {
        isUploadMultiple: false,
        maxTotalFiles: 1,
        isUploadMedia: false,
        allowMimes: FILE_TYPE
      };
    }
  }

  onSave() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const data = this.form.getRawValue();

    if (!data.file && !data.url) {
      this.banner.error(this.translate.__m('ERR-COM-0012'));
      return;
    }

    this.dialogRef.close(data);
  }

  onCancel() {
    this.dialogRef.close();
  }

  handleDrop(event: any) {
    event.preventDefault();

    const files = event.dataTransfer.files;

    if (!files.length) return;

    const isValidName = this.validateFileName(files);

    if (!isValidName) {
      event.target.value = '';
      return;
    }

    if (this.data?.isUploadMultiple && files.length > this.data.maxTotalFiles!) {
      this.showErrorMessageAndReset('ERR-DMAT-0006', event);
      return;
    }

    const isValidType = Array.from(files).every((file: any) =>
      this.utils.validateFileType(file, this.data?.allowMimes)
    );

    if (!isValidType && this.data?.isUploadMedia) {
      this.showErrorMessageAndReset('ERR-DMAT-0005', event);
      return;
    }

    this.form.patchValue({
      file: this.data?.isUploadMultiple ? files : files[0]
    });
  }

  handleFileChange(event: any) {
    const files = event.target.files;

    if (!files.length) return;

    const isValidName = this.validateFileName(files);

    if (!isValidName) {
      event.target.value = '';
      return;
    }

    if (this.data?.isUploadMultiple && files.length > this.data.maxTotalFiles!) {
      this.showErrorMessageAndReset('ERR-DMAT-0006', event);
      return;
    }

    const isValidType = Array.from(files).every((file: any) =>
      this.utils.validateFileType(file, this.data?.allowMimes)
    );

    if (!isValidType && this.data?.isUploadMedia) {
      this.showErrorMessageAndReset('ERR-DMAT-0005', event);
      return;
    }

    this.form.patchValue({
      file: this.data?.isUploadMultiple ? files : files[0]
    });
  }

  validateFileName(files: any): boolean {
    for (const file of files) {
      const isValid = this.utils.validateStringLength(file.name, 128, 64);
      if (!isValid) {
        this.banner.error(this.translate.__m('ERR-COM-0016'));
        return false;
      }
    }
    return true;
  }

  showErrorMessageAndReset(message: string, event: any) {
    event.target.value = '';
    this.banner.error(this.translate.__m(message));
  }

  handleDrag(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }
}
