import { Tenant } from './tenant.model';

export interface LoginData {
  email: string | null;
  password: string | null;
  invitationId?: string;
}

export interface RegisterData {
  email: string | null;
  password: string | null;
  reTypePassword: string | null;
  givenName: string | null;
  familyName: string | null;
  givenNameAlphabet: string | null;
  familyNameAlphabet: string | null;
  displayName: string | null;
  companyName: string | null;
  departmentName: string | null;
}

export interface RegisterData {
  email: string | null;
  password: string | null;
  reTypePassword: string | null;
  givenName: string | null;
  familyName: string | null;
  givenNameAlphabet: string | null;
  familyNameAlphabet: string | null;
  displayName: string | null;
  companyName: string | null;
  departmentName: string | null;
}

export interface VerifyEmailData {
  email: string | null;
  code: string | null;
}

export interface InvitationSecretData {
  code: string;
}

export interface InvitationResponse {
  id: string;
  inviteeEmail: string;
  inviteeUserId: string | null;
  acceptedAt: string | null;
}

export interface RegisterByInvitationData {
  email: string;
  givenNameAlphabet: string;
  familyNameAlphabet: string;
  givenName: string;
  familyName: string;
  displayName: string;
  password: string;
  reTypePassword: string;
  companyName: string;
  departmentName: string;
  invitationId: string;
}

export enum Role {
  GUEST = 100000,
  SYSTEM_ADMIN = 999999,
  MANAGER = 1,
  NORMAL = 0,
  CONNECTED = 2
}

export enum RoleByName {
  GUEST = 'GUEST',
  SYSTEM_ADMIN = 'SYSTEM_ADMIN',
  MANAGER = 'MANAGER',
  NORMAL = 'NORMAL',
  CONNECTED = 'CONNECTED'
}

export enum Permission {
  VIEW = 1,
  EDIT = 2
}

export interface LoggedUserResponse {
  id: string;
  email: string;
  companyName: string;
  displayName: string;
  departmentName: string;
  familyName: string;
  familyNameAlphabet: string;
  givenName: string;
  givenNameAlphabet: string;
  lastLogin: string;
  profileImageUrl: string;
  thumbnailProfileImageUrl: string;
  status: number;
  isSystemAdmin: boolean; // Is root user (can create new tenant)
  tenants: Array<Tenant>;
  version: number;
}

export interface LoginRespone {
  accessToken: string;
  idToken: string;
}

export interface RegisterResponse extends RegisterData {
  id: string | null;
}

export interface ResetPasswordData {
  email: string | null;
}

export interface ResetPasswordResponse {
  message: string;
}

export interface NewPasswordData {
  password: string | null;
  email: string | null;
  code: string | null;
  retypePassword?: string | null;
}

export interface ForceChangePassword {
  proposedPassword: string | null;
  reTypePassword: string | null;
  secretCode: string | null;
}

export interface NewPasswordResponse {
  message: string;
}

export interface OtpResponse {
  otp: string;
}
