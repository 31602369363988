<div
  class="search"
  [ngClass]="className"
>
  <label class="searchIcon"
    ><mat-icon
      matPrefix
      class="text-[20px]"
      >search</mat-icon
    ></label
  >
  <input
    class="form-input outline-none w-full"
    [ngModel]="value"
    (ngModelChange)="onModelChange($event)"
    (keypress)="onHandleKeyPress($event)"
    [placeholder]="placeHolder"
    (blur)="onHandleBlur($event)"
    [disabled]="disabled"
    [spellcheck]="false"
  />
  <label
    class="closeIcon"
    *ngIf="!!value"
    (click)="onHandleClear($event)"
  >
    <mat-icon
      matSuffix
      class="text-[20px]"
      >close</mat-icon
    >
  </label>
</div>
