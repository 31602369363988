import { Component, HostListener, OnInit } from '@angular/core';
import { CheckPermissionService } from './core/services/check-permission.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Meta } from '@angular/platform-browser';
import { EditStatusService } from './core/services/edit-status.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'srm';

  constructor(
    public checkPermissionService: CheckPermissionService,
    public permissionsService: NgxPermissionsService,
    public translate: TranslateService,
    private meta: Meta,
    private editStatusService: EditStatusService
  ) {}

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.editStatusService.getEditingStatus()) {
      $event.preventDefault();
    }
  }

  ngOnInit(): void {
    // Init i18n
    this.translate.addLangs(['en', 'ja']);
    this.translate.setDefaultLang('ja');
    this.translate.use('ja');

    const domain = document.location.hostname

    const oldSrmDomain = `srm.${environment.rootDomain}`

    if (domain == oldSrmDomain) {
      window.location.href = environment.buyerDomain;
    }

    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang?.match(/en|ja/) ? browserLang : 'ja');
  }
}
